<script lang="ts">
	export let name: string;
	import Icon from "svelte-awesome";
	import {
		githubSquare,
		linkedinSquare,
		twitterSquare
	} from "svelte-awesome/icons";
</script>

<header>
	<nav
		class="bg-white dark:bg-gray-800 flex items-center justify-between flex-wrap bg-teal-500 p-6 text-gray-600 dark:text-gray-300 shadow-lg"
	>
		<div class="flex items-center flex-shrink-0 text-white mr-6">
			<img
				src="images/simusr2.v2.min.png"
				class="rounded-full"
				width="30"
				height="30"
				alt="Simone Anile"
			/>
			<span
				class="font-semibold text-xl tracking-tight ml-4 text-gray-600 dark:text-gray-300"
				>Simone Anile</span
			>
		</div>

		<div>
			<div class="lg:hidden" id="menu-button">
				<button
					class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:opacity-80"
				>
					<svg
						class="fill-current h-5 w-5"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
						><title>Menu</title><path
							d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
						/></svg
					>
				</button>
			</div>
		</div>

		<div
			class="hidden w-full flex-grow lg:flex lg:items-center lg:w-auto"
			id="menu"
		>
			<div class="text-sm lg:flex-grow">
				<!-- <button
					class="flex items-center px-3 py-2 mt-4 lg:mt-0 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
					id="theme-button"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-6 w-6"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
						/>
					</svg>
				</button> -->
				<!-- <a
					href="#responsive-header"
					class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
				>
					Docs
				</a>
				<a
					href="#responsive-header"
					class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
				>
					Examples
				</a>
				<a
					href="#responsive-header"
					class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white"
				>
					Blog
				</a> -->
			</div>
			<div class="flex mt-4 lg:mt-0 float-right">
				<button
					class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:opacity-80"
					id="theme-button"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-5 w-5"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
						/>
					</svg>
				</button>
			</div>
			<!-- <div>
				<a
					href="#d"
					class="inline-block text-sm px-4 py-2 leading-none border rounded text-gray-600 dark:text-gray-300 border-white hover:text-teal-500 mt-4 lg:mt-0"
					>Download</a
				>
			</div> -->
		</div>
	</nav>
</header>
<main class="bg-white dark:bg-gray-800 m-6">
	<div class="text-center">
		<h1 class="text-gray-900 dark:text-white text-xl">{name}</h1>
		<p class="text-gray-600 dark:text-gray-300">Personal website</p>
		<p class="text-gray-600 dark:text-gray-300">
			🧑‍💻 IT enthusiast - Full Stack Developer - System analyst
		</p>
	</div>
</main>

<!-- component -->
<footer
	class="footer bg-white dark:bg-gray-800 absolute pt-1 bottom-0 w-full text-gray-600 dark:text-gray-300"
>
	<!-- <div class="container mx-auto px-6">

        <div class="sm:flex sm:mt-8">
            <div class="mt-8 sm:mt-0 sm:w-full sm:px-8 flex flex-col md:flex-row justify-between">
                <div class="flex flex-col">
                    <span class="font-bold text-gray-700 uppercase mb-2">Footer header 1</span>
                    <span class="my-2"><a href="#a" class="text-blue-700  text-md hover:text-blue-500">link 1</a></span>
                    <span class="my-2"><a href="#a" class="text-blue-700  text-md hover:text-blue-500">link 1</a></span>
                    <span class="my-2"><a href="#a" class="text-blue-700  text-md hover:text-blue-500">link 1</a></span>
                </div>
                <div class="flex flex-col">
                    <span class="font-bold text-gray-700 uppercase mt-4 md:mt-0 mb-2">Footer header 2</span>
                    <span class="my-2"><a href="#a" class="text-blue-700 text-md hover:text-blue-500">link 1</a></span>
                    <span class="my-2"><a href="#a" class="text-blue-700  text-md hover:text-blue-500">link 1</a></span>
                    <span class="my-2"><a href="#a" class="text-blue-700 text-md hover:text-blue-500">link 1</a></span>
                </div>
                <div class="flex flex-col">
                    <span class="font-bold text-gray-700 uppercase mt-4 md:mt-0 mb-2">Footer header 3</span>
                    <span class="my-2"><a href="#a" class="text-blue-700  text-md hover:text-blue-500">link 1</a></span>
                    <span class="my-2"><a href="#a" class="text-blue-700  text-md hover:text-blue-500">link 1</a></span>
                    <span class="my-2"><a href="#a" class="text-blue-700  text-md hover:text-blue-500">link 1</a></span>
                </div>
            </div>
        </div>
    </div> -->
	<div class="container mx-auto px-6">
		<!-- <div class="mt-6 border-t-2 border-gray-300 flex flex-col items-center"> -->
		<div class="mt-3 flex flex-col items-center">
			<div class="sm:w-2/3 text-center py-6">
				<p class="text-sm font-bold mb-2">
					© 2021 Simone Anile <a href="https://github.com/simusr2/" alt="GitHub" aria-label="GitHub"><Icon data={githubSquare} scale=2/></a> <a href="https://www.linkedin.com/in/simoneanile/" alt="LinkedIn" aria-label="LinkedIn"><Icon data={linkedinSquare} scale=2/></a> <a href="https://twitter.com/simusr2/" alt="Twitter" aria-label="Twitter"><Icon data={twitterSquare} scale=2/></a>
				</p>
				<p class="text-sm">
					Contact @ <a class="font-bold" href="mailto:simone@simoneanile.com">simone@simoneanile.com</a>
				</p>
				<p class="text-sm">
					<a href="licenses.txt">Licenses</a>
				</p>
				<!-- <p class="flex items-center"><img alt="Netlify Status" width="135" height="20" src="https://api.netlify.com/api/v1/badges/a4a9c374-1b19-40d0-9ccc-003a378d9d8a/deploy-status" /></p> -->
			</div>
		</div>
	</div>
</footer>

<style lang="postcss" global>
	@tailwind base;
	@tailwind components;
	@tailwind utilities;
	#navbar {
		background-color: black;
	}
	/* 
	main {
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 0 auto;
	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	} */
</style>
